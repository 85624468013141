<template>
  <div class="project-booking-refund-history-details">
    <div class="container fluid">
      <refund-details
        isHistory
        :refundId="$route.params.id"
        :getRefundDetailAPI="refundAPI.getRefundDetail"
        :getRejectReasonAPI="refundAPI.getRejectReason"
        :approveRefundAPI="refundAPI.approveRefund"
        :rejectRefundAPI="refundAPI.rejectRefund"
        :downloadFileAPI="bookingAPI.downloadFile"
      ></refund-details>
    </div>
  </div>
</template>

<script>
import RefundDetails from "@/components/GlobalComponents/Shared/ProjectSales/Refund/RefundDetails";

import {
  projectRefund as refundAPI,
  projectBooking as bookingAPI
} from "@/api";

export default {
  components: {
    RefundDetails
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      refundAPI,
      bookingAPI
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
</style>
